<template>
  <div class="store-kiosk" :class="pageStatus" :style="backgroundStyle">
    <!-- <video
      ref="videoPlayer"
      class="background-video"
      :class="{ 'fade-in': isVideoPlaying, 'fade-out': !isVideoPlaying }"
      :src="currentVideo"
      v-show="isVideoPlaying"
      @ended="handleVideoEnd"
      @canplay="onCanPlay"
    ></video> -->
    <video
      ref="videoPlayer"
      class="background-video"
      :class="{ 
        'fade-in': isVideoPlaying,
        'fade-out': !isVideoPlaying 
      }"
      :src="currentVideo"
      v-show="true"
      @ended="handleVideoEnd"
      @canplay="onCanPlay"
    ></video>
    <video
      ref="nextVideoPlayer"
      class="background-video"
      :src="nextVideo"
      style="display: none;"
      preload="auto"
    ></video>
    <div class="camera-container" >
      <video ref="webcam" class="webcam" :class="{ 'show-webcam': showWebcam }" autoplay muted></video>
      <canvas ref="faceCanvas" class="face-canvas" :class="{ 'show-canvas': showCanvas }"></canvas>
    </div>
    <canvas ref="canvas" class="debug-canvas" :class="{ 'show-canvas': showCanvas }" willReadFrequently style="display: none;"></canvas>
    <div class="content" :class="{ 'fade-in': pageStatus !== 'loading', 'fade-out': pageStatus === 'loading' }">
      <h1>RoboTalk</h1>
      <div class="kiosk-content">
        <!-- 키오스크 컨텐츠 -->
        <div v-if="pageStatus === 'ready'" class="ready-state">
          <!-- <p>화면을 터치하거나 말씀해 주세요.</p> -->
        </div>
        <div v-else-if="pageStatus === 'playing'" class="playing-state">
          <!-- <p>영상을 시청 중입니다.</p> -->
        </div>
        <div v-else-if="pageStatus === 'chatting'" class="chatting-state">
          <!-- <p>음성 채팅 중입니다.</p> -->
        </div>
      </div>
      <VoiceChat 
        ref="voiceChat"
        :storeId="Number(storeId)" 
        @message="handleVoiceChatMessage"
        @recordingStarted="handleRecordingStarted"
        @recordingEnded="handleRecordingEnded"
        @voiceChatError="handleVoiceChatError"
        @recordingStateChanged="handleVoiceChatStateChange"
        @voice-chat-ended="handleVoiceChatEnded"
      />
    </div>
    
    <div v-if="isWaitingForUserInput" class="waiting-indicator">
      말씀해 주세요...
    </div>
    <div v-if="showError" class="error-message">
      {{ errorMessage }}
    </div>
    
    <!-- 디버그 컨트롤 (개발 중에만 표시) -->
    <div v-if="isDevelopment" class="debug-controls">
      <button @click="toggleVideo">{{ isVideoPlaying ? '비디오 중지' : '비디오 시작' }}</button>
      <button @click="toggleMute">{{ isMuted ? '소리 켜기' : '소리 끄기' }}</button>
      <button @click="toggleWebcam">웹캠 {{ showWebcam ? '숨기기' : '보이기' }}</button>
      <button @click="toggleCanvas">캔버스 {{ showCanvas ? '숨기기' : '보이기' }}</button>
    </div>

    <button @click="refreshPage" class="refresh-button">
      <img src="@/media/icon/refresh.svg" alt="새로고침" style="width: 4em; height: 4em;">
    </button>
    
  </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted, watch } from 'vue';
import VoiceChat from '@/components/VoiceChat.vue?v=33';
import { useRoute } from 'vue-router';
import axios from 'axios';
import * as faceapi from '@vladmandic/face-api';
import _ from 'lodash';
const LOG_OUTPUT = true;

const log = (...args) => {
  if (LOG_OUTPUT) {
    console.log(...args);
  }
};

let faceDetectionMissCount = 0; // 전역 변수로 선언
const MAX_FACE_DETECTION_MISS = 5;

export default {
  name: 'StoreKiosk',
  components: {
    VoiceChat
  },    
  setup() {
    const route = useRoute();
    const storeId = ref(route.params.id || '1'); // URL서 ID를 가져오거나 기본값으로 '1' 설정
    console.log('Store ID:', storeId.value);
    const storeName = ref('');
    const backgroundImage = ref(require('@/media/oliveyoung/oliveyoung_bg.jpg'));

    // 상태 정의
    const pageStatus = ref('loading');
    const isVideoPlaying = ref(false);
    const isVoiceChatActive = ref(false);
    const voiceChatState = ref('idle');
    const isFaceDetected = ref(false);
    const isSignificantMotionDetected = ref(false);
    const isWaitingForUserInput = ref(false);
    const showError = ref(false);
    const errorMessage = ref('');

    // 비디오 관련
    const videoPlayer = ref(null);
    const nextVideoPlayer = ref(null);
    const chattingLoopVideoPlayer = ref(null);
    const currentVideo = ref(require('@/media/oliveyoung/intro/ko-KR.webm')); //en-US
    const nextVideo = ref(require('@/media/oliveyoung/intro/ko-KR.webm')); //en-US
    // const chattingLoopVideo = ref(require('@/media/oliveyoung/listening_loop_video.webm'));

    // 웹캠 및 캔버스 관련
    const webcam = ref(null);
    const faceCanvas = ref(null);
    const canvas = ref(null);
    const showWebcam = ref(true);
    const showCanvas = ref(true);

    // 타이머
    const userInputWaitTimer = ref(null);
    const faceDisappearanceTimer = ref(null);

    // 얼굴 감지 관련
    const lastDetectedFace = ref(null);
    const currentDetectedFace = ref(null);

    // 상수
    const MOTION_DETECTION_THRESHOLD = 10;
    const USER_INPUT_WAIT_TIME = 7000;
    const FACE_DISAPPEARANCE_TIMEOUT = 5000;

    // 개발 모드 확인
    const isDevelopment = process.env.NODE_ENV === 'development';

    // VoiceChat 컴포넌트 참조
    const voiceChat = ref(null);

    // 함수 정의
    const startInitialVideo = () => {
      pageStatus.value = 'playing';
      isVideoPlaying.value = true;
      if (videoPlayer.value) {
        videoPlayer.value.play();
      }
    };

    const playNextVideo = () => {
      // if (pageStatus.value !== 'ready') return;
      
      pageStatus.value = 'playing';
      isVideoPlaying.value = true;
      if (nextVideoPlayer.value) {
        currentVideo.value = nextVideo.value;
        videoPlayer.value.load();
        videoPlayer.value.play();
      }
    };

    const handleVideoEnd = () => {
      console.log('Video ended');
      isVideoPlaying.value = false;
      //아래 함수로 대체됨, 검증 필요 checkAndHandleUserPresence();
      updatePageStatusBasedOnDetection();
    };

    const initiateVoiceInteraction = () => {
      console.log('Initiating voice interaction');
      if (voiceChatState.value !== 'idle') {
        console.log('Voice interaction is not idle, current state:', voiceChatState.value);
        return;
      }
      
      isVoiceChatActive.value = true;
      if (voiceChat.value) {
        console.log('Calling startRecording on VoiceChat component');
        voiceChat.value.startVoiceChatLoop();
      } else {
        console.error('VoiceChat component reference is null');
      }
    };

    const terminateVoiceInteraction = () => {
      if (voiceChat.value) {
        voiceChat.value.stopVoiceChatLoop();
      }
      isVoiceChatActive.value = false;
    };

    const detectFaces = async () => {
      if (!webcam.value || !faceCanvas.value) return;

      // 캔버스 크기를 웹캠 크기에 맞게 조정
      faceCanvas.value.width = webcam.value.videoWidth;
      faceCanvas.value.height = webcam.value.videoHeight;

      const detections = await faceapi.detectAllFaces(webcam.value);
      isFaceDetected.value = detections.length > 0;
      console.log('Face detected:', isFaceDetected.value);
      if (isFaceDetected.value) {
        currentDetectedFace.value = detections[0];
        // 얼굴 감지 결과를 캔버스에 그리기
        const ctx = faceCanvas.value.getContext('2d');
        ctx.clearRect(0, 0, faceCanvas.value.width, faceCanvas.value.height);
        faceapi.draw.drawDetections(faceCanvas.value, detections);
      }
    };

    const detectMotion = () => {
      if (!canvas.value) return;

      const ctx = canvas.value.getContext('2d', { willReadFrequently: true });

      // 이전 프레임 데이터 저장
      if (!lastImageData) {
        lastImageData = ctx.getImageData(0, 0, canvas.value.width, canvas.value.height);
        return;
      }

      const currentImageData = ctx.getImageData(0, 0, canvas.value.width, canvas.value.height);
      const diff = calculateMotionDifference(currentImageData.data, lastImageData.data);
      isSignificantMotionDetected.value = diff > MOTION_DETECTION_THRESHOLD;

      // 현재 프레임을 다음 비교를 위해 저장
      lastImageData = currentImageData;
    };

    // 페이지 상태 업데이트 함수 : checkAndHandleUserPresence()를 대체할 예정!
    const updatePageStatusBasedOnDetection = () => {
      // 화면 변화 감지 상태에 따라 페이지 상태 업데이트
      if (pageStatus.value === 'chatting') {
        if (isFaceDetected.value) {
          return;
        } else {
          // 5초간 얼굴이 감지되지 않으면 대기 상태로 전환
          faceDisappearanceTimer.value = setTimeout(() => {
            if (!isFaceDetected.value) {
              transitionToReadyState();
            }
          }, FACE_DISAPPEARANCE_TIMEOUT);
        }
      } else if (pageStatus.value === 'playing') {        
        if(isVideoPlaying.value) {
          return;
        } else {
          if(isFaceDetected.value) {
            transitionToChattingState();
          } else if (isSignificantMotionDetected.value) {
            //다시 얼굴 검출될 수 있으므로 몇 초 있다가 재생하는게 나을 수 도 있겠음. 일단 대기상태 진입
            transitionToReadyState()
          } else {
            transitionToReadyState();
          }
        }
      } else if (pageStatus.value === 'ready') {
        if(isFaceDetected.value) {
          transitionToChattingState();
        }
      }
    };

    // const checkAndHandleUserPresence = () => {
    //   console.log('Checking user presence');
    //   console.log('Face detected:', isFaceDetected.value);
    //   console.log('Motion detected:', isSignificantMotionDetected.value);
    //   if (isFaceDetected.value) {
    //     if (voiceChatState.value === 'idle') {
    //       transitionToChattingState();
    //     }
    //   } else if (isSignificantMotionDetected.value) {
    //     transitionToPlayingState();
    //   } else {
    //     transitionToReadyState();
    //   }
    // };

    const isFacePresent = () => {
      return isFaceDetected.value;
    };

    const calculateMotionDifference = (currentData, previousData) => {
      if (!previousData) return 0;
      let diff = 0;
      for (let i = 0; i < currentData.length; i += 4) {
        diff += Math.abs(currentData[i] - previousData[i]);
      }
      return diff / (currentData.length / 4);
    };

    const handleVoiceChatStateChange = (newState) => {
      console.log('Voice chat state changed:', newState);
      voiceChatState.value = newState;
      if (newState === 'idle') {
        checkFacePresenceAndAct();
      }
    };

    const checkFacePresenceAndAct = () => {
      if (!isFacePresent()) {
        faceDisappearanceTimer.value = setTimeout(() => {
          if (!isFacePresent()) {
            transitionToReadyState();
          }
        }, FACE_DISAPPEARANCE_TIMEOUT);
      }
    };

    const transitionToPlayingState = () => {
      console.log('transitionToPlayingState()');
      if(isVideoPlaying.value) {
        pageStatus.value = 'playing';
        return;
      }

      pageStatus.value = 'playing';
      isVideoPlaying.value = true;
      playNextVideo();
    };

    const transitionToChattingState = () => {
      console.log('Transitioning to chatting state');
      pageStatus.value = 'chatting';
    };

    const transitionToReadyState = () => {
      console.log('transitionToReadyState()');
      if(isVideoPlaying.value) {
        return;
      }

      pageStatus.value = 'ready';
      isVideoPlaying.value = false;
      isVoiceChatActive.value = false;
    };

    // 디버그 컨트롤 함수
    const toggleVideo = () => {
      isVideoPlaying.value = !isVideoPlaying.value;
      if (isVideoPlaying.value) {
        videoPlayer.value.play();
      } else {
        videoPlayer.value.pause();
      }
    };

    const toggleMute = () => {
      if (videoPlayer.value) {
        videoPlayer.value.muted = !videoPlayer.value.muted;
      }
    };
  
    const backgroundStyle = computed(() => ({
      backgroundImage: `url(${backgroundImage.value})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      width: '100vw',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }));
  
    const toggleWebcam = () => {
      showWebcam.value = !showWebcam.value;
    };

    const toggleCanvas = () => {
      showCanvas.value = !showCanvas.value;
    };

    // 초기화 및 정리
    onMounted(async () => {
      try {
        console.log('Fetching store info for ID:', storeId.value);
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/stores/${storeId.value}`);
        console.log('API response:', response.data);
        storeName.value = response.data.name;
        
        // 웹캠 초기화
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
          const stream = await navigator.mediaDevices.getUserMedia({ video: true });
          if (webcam.value) {
            webcam.value.srcObject = stream;
          }
        }
        
        await faceapi.loadSsdMobilenetv1Model('/models');
        
        startDetectionLoop();
        pageStatus.value = 'ready';
        startInitialVideo();
      } catch (error) {
        console.error('초기화 중 오류 발생:', error);
        console.error('오류 상세:', error.response ? error.response.data : '응답 없음');
        console.error('오류 스택:', error.stack);
        showError.value = true;
        errorMessage.value = `키오스크 초기화 중 오류가 발생했습니다: ${error.message}`;
      }
    });

    onUnmounted(() => {
      stopDetectionLoop();
    });

    // 감지 루프
    let detectionInterval;
    const startDetectionLoop = () => {
      detectionInterval = setInterval(() => {
        detectFaces();
        detectMotion();
        updatePageStatusBasedOnDetection();
      }, 1000); // 1초마다 감지
    };

    const stopDetectionLoop = () => {
      clearInterval(detectionInterval);
    };

    // 워치 함수
    watch(pageStatus, (newStatus, oldStatus) => {
      console.log(`상태 변경: ${oldStatus} -> ${newStatus}`);
      if (newStatus === 'chatting') {
        initiateVoiceInteraction();
      }
    });

    let lastImageData = null;

    const handleRecordingStarted = () => {
      console.log('handleRecordingStarted()');
      voiceChatState.value = 'recording';
    };

    const handleRecordingEnded = () => {
      console.log('VhandleRecordingEnded()');
      voiceChatState.value = 'idle';
      checkFacePresenceAndAct();
    };

    const handleVoiceChatError = (error) => {
      console.error('Voice chat error:', error);
      voiceChatState.value = 'idle';
      showError.value = true;
      errorMessage.value = `음성 채팅 중 오류가 발생했습니다: ${error}`;
    };

    const handleVoiceChatMessage = (message) => {
      console.log('Received voice chat message:', message);
      // 필요한 경우 메시지 처리 로직 추가
    };

    const handleVoiceChatEnded = () => {
      console.log('Voice chat ended');
      // 필요한 추가 로직
    };

    const getAudioDevices = async () => {
      const devices = await navigator.mediaDevices.enumerateDevices();
      return devices.filter(device => device.kind === 'audioinput');
    };

    const selectAudioDevice = async (deviceId) => {
      const constraints = {
        audio: { deviceId: { exact: deviceId } }
      };
      stream = await navigator.mediaDevices.getUserMedia(constraints);
      // 새로운 스트림으로 오디오 컨텍스트 재설정
    };

    // 얼굴 감지 및 음성 채팅 상태 모니터링
    watch([isFaceDetected, voiceChatState], ([newFaceDetected, newVoiceChatState], oldValues) => {
      console.log('faceDetectionMissCount:', faceDetectionMissCount);
      console.log('voiceChatState:', newVoiceChatState);
      if (newFaceDetected !== oldValues[0] || newVoiceChatState !== oldValues[1]) {
        if (pageStatus.value === 'chatting') {
          if (!newFaceDetected) {
            faceDetectionMissCount++;
          } else {
            faceDetectionMissCount = 0;
          }

          if (faceDetectionMissCount >= MAX_FACE_DETECTION_MISS 
              && (newVoiceChatState === 'idle' || newVoiceChatState === 'recording')) {
            console.log('얼굴이 5회 연속 감지되지 않고 음성 채팅이 idle 또는 recording 상태입니다. 재생 상태로 전환합니다.');
            transitionToPlayingState();
          }
        } else {
          faceDetectionMissCount = 0;
        }
      }
    });
    
    // pageStatus가 변경될 때마다 faceDetectionMissCount를 리셋
    watch(pageStatus, (newStatus) => {
      if (newStatus !== 'chatting') {
        faceDetectionMissCount = 0;
      }
    });

    const refreshPage = () => {
      window.location.reload();
    };

    return {
      pageStatus,
      backgroundStyle,
      isVideoPlaying,
      isVoiceChatActive,
      voiceChatState,
      isFaceDetected,
      isSignificantMotionDetected,
      isWaitingForUserInput,
      showError,
      errorMessage,
      videoPlayer,
      nextVideoPlayer,
      currentVideo,
      nextVideo,
      webcam,
      faceCanvas,
      canvas,
      showWebcam,
      showCanvas,
      isDevelopment,
      storeName,
      storeId,
      toggleVideo,
      toggleMute,
      toggleWebcam,
      toggleCanvas,
      handleVideoEnd,
      handleVoiceChatStateChange,
      voiceChat,
      handleRecordingStarted,
      handleRecordingEnded,
      handleVoiceChatError,
      handleVoiceChatMessage,
      handleVoiceChatEnded,
      refreshPage,
    };
  },
};
</script>

<style scoped>
.store-kiosk {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  visibility: hidden; /* 기본 상태 */
}

.background-video.fade-in {
  opacity: 1;
  visibility: visible;
}

/* fade-out 클래스에서 visibility를 visible로 유지 */
.background-video.fade-out {
  opacity: 1;
  visibility: visible; /* none에서 visible로 수정 */
}

.debug-canvas {
  position: fixed;
  bottom: 10px;
  left: 10px;
  width: 320px;
  height: 240px;
  object-fit: cover;
  z-index: 11;
  display: none;
}

.camera-container {
  position: fixed;
  bottom: 10px;
  left: 10px;
  width: 320px;
  height: 240px;
  z-index: 10;
  display: block; /* 항상 표시 */
}

.webcam {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block; /* 항상 표시 */
}

.face-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block; /* 항상 표시 */
}

.show-webcam, .show-canvas {
  display: block;
}

.content {
  position: relative;
  z-index: 20;
  color: white;
  padding: 20px;
  transition: opacity 0.5s ease-in-out;
}

.fade-in {
  opacity: 1;
  visibility: visible;
}

.fade-out {
  opacity: 0;
  visibility: hidden;
}

.ready-state, .playing-state, .chatting-state {
  text-align: center;
  font-size: 1.5em;
  margin-top: 20px;
}

.waiting-indicator {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1.2em;
}

.error-message {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(255, 0, 0, 0.8);
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  z-index: 1000;
}

.debug-controls {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.debug-controls button {
  margin-left: 10px;
}

.refresh-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.refresh-button img {
  width: 4em;
  height: 4em;
}

</style>
